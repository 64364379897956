/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-09-25",
    versionChannel: "nightly",
    buildDate: "2023-09-25T00:19:43.940Z",
    commitHash: "01b1e36ef5443f7d646cab83ef5d3fdf893726c2",
};
